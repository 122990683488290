import React from "react"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import NavAbout from "../../components/nav/about"
// import Radar from "../../components/chart/radar"

const Review = () => (
    <Layout title="我们的评测标准 - wall101">
        <NavAbout />
        <div id="review" className="about" >
            <div className="title" >
                <div className="container" >
                    <h1>我们的评测标准</h1>
                </div>
            </div>
            <div className="content container">
                <h2>翻墙VPN</h2>
                <p>针对翻墙VPN，我们从连接速度，延迟度，客服，更新速度，安全性和稳定性六个角度来进行评价。每个维度满分为10分</p>
                <div id="radar">
                    {/* <Radar vpnSlug="express" cate=""/> */}
                </div>
                <h4>连接速度</h4>
                <p>在连接速度的评价下，我们评测的是翻墙后VPN的下载速度与上传速度。</p>
                <p>速度测试我们选择的是speedtest.cn, 测试节点则是VPN连接后，和VPN服务器所在位置最近的节点。</p>
                <p>我们在这里不仅仅考虑的是上传下载能够达到多快，还有不同的地区下载速度与上传速度能否都达到某种标准。也就是连接速度的地区覆盖程度。</p>
                <p>详细测速方法，请查看<Link to="/about/speedtest/">测速方法</Link></p>
                <h4>延迟度（Ping）</h4>
                <p>速度测试同样选择的是speedtest.cn。这里我们会考虑延迟度Ping和抖动。</p>
                <p>延迟度对于看视频影响不大，但是对于玩游戏与在线会议来说，是一个比较重要的参考指标。</p>
                <p>延迟度大就会让游戏中玩家决策和实际的状况出现差异，尤其是射击游戏中。对于开会的员工，则是会觉得双方讲话卡卡的。</p>
                <h4>客服</h4>
                <p>道高一尺，魔高一丈。因为防火墙是在不断更新的，同样，VPN也需要不断更新。很多时候，VPN受到防火墙的阻拦也会面临各种各样的问题。</p>
                <p>这时候，如果能够有比较高效的客服支持，问题就能很快的解决。</p>
                <p>客服这边我们从响应速度，解决能力还有是否支持中文来进行评分。</p>
                <h4>更新速度</h4>
                <p>这边我们评估的是VPN的更新速度。</p>
                <p>尤其是进入敏感时期，很多VPN都会遇到各种各样的问题。关于敏感时期，可查看<Link to="/fanqiang-vpn/#敏感时期">这里</Link>。</p>
                <p>这里我们评估的是厂商更新的频率和面对突发状况时，VPN更新所需要花费的时间。</p>
                <h4>安全性</h4>
                <p>VPN不仅仅是为了绕过防火墙，更重要的是，翻墙的过程中，能否有效的保护我们的安全。</p>
                <p>安全性我们评估的就是VPN的信息加密程度和信息安全性。</p>
                <p>主要的考虑维度是连接断开时，能否阻止流量出入（kill switch），服务器对于浏览日志的处理方式，VPN的连接协议以及加密算法的强度。</p>
                <h4>稳定度</h4>
                <p>这里我们评估的就是VPN连接后，能否稳定的保持连接状态，还是说会时不时的断开。</p>
                <h3>大厂与小作坊</h3>
                <p>一般来说，小作坊出品的VPN相比大厂能够有很高的连接速度。这主要是因为中国的防火墙会对连接到境外的加密流量进行识别。防火墙看不到这些流量内部的具体信息，但是能够察觉连接的服务器是哪里（也就是VPN的节点位置）。</p>
                <p>对于大厂来说，因为用户数量够多，这些服务器节点也更容易被防火墙识别成VPN服务器，封锁起来更容易。</p>
                <p>相对的，小作坊的用户数相对比较少，这些流量更难被识别，所以防火墙的屏蔽效果也就更弱一些。</p>
                <p>这样就造成小作坊的VPN连接速度较那些大厂例如NordVPN之类的快很多。</p>
                <p>但小作坊的速度快也不是没有缺点，小作坊相对大厂来讲安全性上面就差了很多，而且稳定度也会大打折扣。</p>
                <p>所以我们在评测的过程中会把这些变量都完完整整的展示给大家，各位也根据自己的需求，来选择适合自己的翻墙VPN。</p>
                <h2>回国VPN</h2>
                <p>针对回国VPN，我们从连接速度，延迟度，客服，价格，使用体验和稳定性六个角度来进行评价。每个维度满分为10分</p>
                <div id="radar">
                    {/* <Radar vpnSlug="malus" cate="china"/> */}
                </div>
                <h4>连接速度 Ping 客服</h4>
                <p>同翻墙VPN一样，我们也采用speedtest.cn进行测试。我们的测试标准可参见上文翻墙VPN的标准</p>
                <p>客服评价标准也同翻墙VPN部分一致。</p>
                <h4>价格</h4>
                <p>这里我们主要从两个角度来评价。是否有提供免费的套餐让用户试用，以及VIP的价格是多少。</p>
                <h4>试用体验</h4>
                <p>这里包括界面设计是否简单易懂，而且因为回国VPN大多提供免费的套餐服务，我们这里也会对是否有广告弹窗，广告的干扰程度作出评判。</p>
                <h4>稳定性</h4>
                <p>我们对稳定性的评价主要在两个角度：</p>
                <p>第一是回国VPN的速度能否长时间保持一个相对高的状态。也就是连接线路的稳定性。</p>
                <p>第二则是回国VPN是否容易断线。因为回国没有受到防火墙的阻拦，这里我们参考的是软件本身的稳定程度，能否实现长时间的连接状态。</p>
            </div>
        </div>
    </Layout>
)

export default Review